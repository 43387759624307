<template>
  <b-card>
    <b-row>
      <b-col>
        <div class="d-flex align-items-center mb-1">
          <skeletor
            width="32px"
            height="14px"
            class="mr-1"
          />
          <skeletor
            width="40%"
            height="14px"
            class="mr-1"
          />
          <skeletor
            width="30%"
            height="18px"
            class="mr-1"
          />
        </div>
        <skeletor
          width="70%"
          height="25px"
          class="mb-50"
        />
        <skeletor
          width="40%"
          height="14px"
        />
      </b-col>
      <b-col>
        <skeletor
          width="45%"
          height="12px"
          class="mb-50"
        />
        <skeletor
          width="70%"
          height="18px"
          class="mb-50"
        />
        <skeletor
          width="65%"
          height="18px"
          class="mb-50"
        />
        <skeletor
          width="45%"
          height="18px"
        />
      </b-col>
      <b-col>
        <skeletor
          width="45%"
          height="12px"
          class="mb-50"
        />
        <div class="d-flex align-items-center flex-wrap">
          <skeletor
            width="30%"
            height="18px"
            class="mr-50 mb-50"
          />
          <skeletor
            width="40%"
            height="18px"
            class="mr-50 mb-50"
          />
          <skeletor
            width="20%"
            height="18px"
            class="mr-50 mb-50"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'LeadCardSkeleton',
  components: {
    BCard, BRow, BCol,
  },
}
</script>

<style scoped>

</style>
